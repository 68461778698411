import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/database';
import 'firebase/compat/analytics';
import firestar from 'firestar'

const firebaseConfig = {
	apiKey: "AIzaSyCOPg09ZqJayfSIVdiyn6JFUQEF5IPv2T4",
	authDomain: "continuity-gifting-app.firebaseapp.com",
	databaseURL: "https://continuity-gifting-app-default-rtdb.firebaseio.com",
	projectId: "continuity-gifting-app",
	storageBucket: "continuity-gifting-app.appspot.com",
	messagingSenderId: "814625968432",
	appId: "1:814625968432:web:6ec7bad94761bcf99eb8f5",
	measurementId: "G-76CXQ7JZXF"
};

const env = process.env.REACT_APP_ENV

if (firebase.apps.length === 0) {
	const app = firebase.initializeApp(firebaseConfig)
	firebase.analytics()
	firestar.init(
		'admin',
		'browser',
		() => firebase.firestore,
		() => firebase.auth,
		() => firebase.storage,
		() => firebase.database,
		env == 'emulator' ? {
			app: app as any,
			host: 'localhost',
			port: {
				auth: 9099,
				database: 9000,
				firestore: 8080,
				storage: 9199
			}
		} : undefined
	)
}

const auth = firebase.auth
const firestore = firebase.firestore
const storage = firebase.storage

export { auth, firestore, storage, env }