import { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Users from 'cs-connect/lib/Users'
import TextField from '@material-ui/core/TextField'
import { ActivityIndicator } from 'react-native'
import CheckBox from './CheckBox'

export default function SendMessage(props: {
	onCancel: () => void,
	user?: Users.User
}) {

	const [title, setTitle] = useState('')
	const [description, setDescription] = useState('')
	const [imageUrl, setImageUrl] = useState('')
	const [topic, setTopic] = useState<string | undefined>()
	const [showLoader, setShowLoader] = useState(false)
	const [onlyTopic, setOnlyTopic] = useState(false)
	const [showResult, setShowResult] = useState<string[]>()
	const user = props.user

	return (
		<Grid container md={8}>
			<div style={{
				background: 'white',
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				padding: '3em',
				justifyContent: 'center',
				borderRadius: '5px'
			}}>
				<div style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}>
					<h1>Compose message</h1>
					<CheckBox checked={onlyTopic} title={'Dont send to user'} onPress={() => setOnlyTopic(!onlyTopic)} />
				</div>
				{showLoader || showResult ? (
					<>
						{showResult && (
							<>
								<label>Result</label>
								<br/>
								<p>{showResult.map((message) => (
									<div style={{ marginBottom: '1em' }}>{message}</div>
								))}</p>
								<button onClick={props.onCancel} style={{
									alignSelf: 'flex-end',
									width: 100
								}}>Close</button>
							</>
						)}
						{showLoader && (
							<ActivityIndicator />
						)}
					</>
				) : (
					<>
						<TextField
							variant='outlined'
							label='Title'
							value={title}
							onChange={(e) => setTitle(e.target.value)} />
						<br />
						<TextField
							variant='outlined'
							label='Description'
							value={description}
							onChange={(e) => setDescription(e.target.value)} />
						<br />
						<TextField
							variant='outlined'
							label='Image url'
							value={imageUrl}
							onChange={(e) => setImageUrl(e.target.value.trim())} />
						<br />
						<TextField
							variant='outlined'
							label='Topic'
							value={topic}
							onChange={(e) => setTopic(e.target.value.trim())} />
						<br />
						<button style={{ height: 50 }} onClick={onClickSend}>Send</button>
						<br />
						<button style={{ height: 50 }} onClick={props.onCancel}>Cancel</button>

					</>
				)}
			</div>
		</Grid>
	)

	async function onClickSend() {
		setShowLoader(true)
		try {
			
			const tokens = onlyTopic === true ? [] : (user?.token ? [user.token] : [])

			const messageResponse = await Users.sendNotification({
				title: title,
				description: description,
			},
				{},
				tokens,
				topic
			)
			const responses = messageResponse.response.responses ?? []
			console.log('Notification Response:', messageResponse)
			const messages = responses.map(({ success, error, messageId }, index) => {
				if (success === true) {
					return `${index + 1}. Successful: ` + messageId
				} else {
					return `${index + 1}. Failed: ` + error.message
				}
			})
			setShowResult(messages)
		} catch (error) {
			console.log('Error Sending Notification', error)
		}
		setShowLoader(false)
	}
}
