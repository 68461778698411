"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const providers = {
    'ups': {
        name: 'UPS',
        regex: /\b1Z[A-Z0-9]{16}\b/,
        trackingTemplate: 'http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=TRACKING_LINK&loc=en_in'
    },
    'usps': {
        name: 'USPS',
        regex: /\b([A-Z]{2}\d{9}[A-Z]{2}|(420\d{9}(9[2345])?)?\d{20}|(420\d{5})?(9[12345])?(\d{24}|\d{20})|82\d{8})\b/,
        trackingTemplate: 'https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=TRACKING_LINK'
    },
    'fedex': {
        name: 'FedEx',
        regex: /\b([0-9]{12}|100\d{31}|\d{15}|\d{18}|96\d{20}|96\d{32})\b/,
        trackingTemplate: 'https://www.fedex.com/fedextrack/?tracknumbers=TRACKING_LINK&cntry_code=us'
    }
};
var Shipping;
(function (Shipping) {
    function getTrackingProvider(order) {
        var _a;
        const metadata = order.metadata;
        const fulfillments = ((_a = metadata === null || metadata === void 0 ? void 0 : metadata.fulfillments) !== null && _a !== void 0 ? _a : []);
        let trackingNumber;
        let trackingLink;
        if (fulfillments.length > 0) {
            const fulfillment = fulfillments[0];
            trackingNumber = fulfillment['tracking_number'];
            trackingLink = fulfillment['tracking_url'];
        }
        if ((trackingLink !== null && trackingLink !== void 0 ? trackingLink : '').trim().length == 0) {
            if ((trackingNumber !== null && trackingNumber !== void 0 ? trackingNumber : '').length > 0) {
                const result = Object.keys(providers).find((key) => {
                    var _a;
                    const provider = providers[key];
                    const matches = (_a = trackingNumber.match(provider.regex)) !== null && _a !== void 0 ? _a : [];
                    if (matches.length > 0) {
                        return true;
                    }
                    return false;
                });
                const provider = providers[result];
                if (provider) {
                    const trackingUrl = provider.trackingTemplate.replace('TRACKING_LINK', trackingNumber);
                    provider.trackingUrl = trackingUrl;
                    return provider;
                }
            }
            return undefined;
        }
        else {
            return {
                trackingUrl: trackingLink
            };
        }
    }
    Shipping.getTrackingProvider = getTrackingProvider;
})(Shipping || (Shipping = {}));
exports.default = Shipping;
