"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const firestar_1 = require("firestar");
var Settings;
(function (Settings) {
    async function getAppSettings() {
        return new Promise(async (resolve, reject) => {
            try {
                const appSettings = await firestar_1.Actions.readDocument('settings/app');
                resolve(appSettings);
            }
            catch (error) {
                reject(error);
            }
        });
    }
    Settings.getAppSettings = getAppSettings;
})(Settings || (Settings = {}));
exports.default = Settings;
