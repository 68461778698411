import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router,
} from "react-router-dom";

const env = process.env.REACT_APP_ENV

ReactDOM.render(
  <React.StrictMode>
    <style type="text/css">{`
        @font-face {
      font-family: 'MaterialIcons';
      src: url(${require('react-native-vector-icons/Fonts/MaterialIcons.ttf')}) format('truetype');
    } 
      @font-face {
        font-family: 'FontAwesome';
        src: url(${require('react-native-vector-icons/Fonts/FontAwesome.ttf')}) format('truetype');
      }
    `}</style>
    <Router>
      {env == 'emulator' && <div style={{
        background: '#32a891',
        width: '100%',
        textAlign: 'center',
        color: 'white'
      }}>{env}</div>}
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
