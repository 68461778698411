"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocationCheckOptions = void 0;
const firestar_1 = require("firestar");
exports.LocationCheckOptions = {
    radius: 'Radius',
    bounds: 'Bounds'
};
var Casinos;
(function (Casinos) {
    function getCasinos(descriptor) {
        return firestar_1.Actions.readCollection('casinos', descriptor);
    }
    Casinos.getCasinos = getCasinos;
    function getCasinoInfo(casino) {
        return {
            name: casino.name,
            id: casino.id
        };
    }
    Casinos.getCasinoInfo = getCasinoInfo;
    function getCasinoWithId(id, readOptions) {
        return firestar_1.Actions.readDocument(`casinos/${id}`, readOptions);
    }
    Casinos.getCasinoWithId = getCasinoWithId;
    function addCasino(document) {
        return firestar_1.Actions.addToCollection('casinos', document);
    }
    Casinos.addCasino = addCasino;
    function updateCasino(id, document) {
        return firestar_1.Actions.updateGeoDocument(id, document, 'casinos');
    }
    Casinos.updateCasino = updateCasino;
    function addUser(casinoId, userId, document) {
        return firestar_1.Actions.writeDocument(`casinos/${casinoId}/users/${userId}`, document);
    }
    Casinos.addUser = addUser;
    function deleteUser(casinoId, userId) {
        return firestar_1.Actions.deleteDocument(`casinos/${casinoId}/users/${userId}`);
    }
    Casinos.deleteUser = deleteUser;
    function getUsers(casinoId, descriptor) {
        return firestar_1.Actions.readCollection(`casinos/${casinoId}/users`, descriptor);
    }
    Casinos.getUsers = getUsers;
    function uploadThumbnail(casinoId, data) {
        return firestar_1.Storage.uploadFileDataToPath(`casinos/${casinoId}/thumbnail.png`, data, 'data_url', {
            contentType: 'image/ong'
        });
    }
    Casinos.uploadThumbnail = uploadThumbnail;
    function uploadCoverImage(casinoId, data) {
        return firestar_1.Storage.uploadFileDataToPath(`casinos/${casinoId}/cover-image.png`, data, 'data_url', {
            contentType: 'image/ong'
        });
    }
    Casinos.uploadCoverImage = uploadCoverImage;
    function createAccessKey(casinoId, document) {
        return firestar_1.Actions.addToCollection(`casinos/${casinoId}/accessKeys`, document);
    }
    Casinos.createAccessKey = createAccessKey;
    function getAccessKeys(casinoId) {
        return firestar_1.Actions.readCollection(`casinos/${casinoId}/accessKeys`);
    }
    Casinos.getAccessKeys = getAccessKeys;
    function validateAccessKey(casinoId, accessKey) {
        return new Promise((resolve, reject) => {
            try {
                if (accessKey.trim().length == 0) {
                    throw new Error('No Access Key Found');
                }
                const response = firestar_1.Actions.readDocument(`casinos/${casinoId}/accessKeys/${accessKey}`);
                if (response == null) {
                    throw new Error('Invalid Access Key');
                }
                resolve(response);
            }
            catch (error) {
                reject(error);
            }
        });
    }
    Casinos.validateAccessKey = validateAccessKey;
})(Casinos || (Casinos = {}));
exports.default = Casinos;
