import { View, Text, FlatList } from 'react-native'
import React, { useEffect, useState } from 'react'
import { Support } from 'cs-connect'
import { Alert } from './utils/Alert.web'
import Progress from './utils/Progress'
import Paper from '@mui/material/Paper'
import TextField from './utils/TextField'
import Button from './components/Button'
import Icon from './components/Icon'
import lodash from 'lodash';
import firestar from 'firestar'
import { SupportOptions } from 'cs-connect/lib/Support'
import moment from 'moment'
import Backdrop from '@mui/material/Backdrop'
import CloseIcon from '@material-ui/icons/Close'

export default function Chats(props:{
   orderId: string,
   onClickClose: () => void
}) {

   const { orderId, onClickClose } = props as {
      orderId: string,
      onClickClose: () => void
   }
   const [chat, setChat] = useState<Support.Chat>()
   const [messages, setMessages] = useState<Support.Message[]>([])
   const [showLoader, setShowLoader] = useState(false)
   const [newMessage, setNewMessage] = useState<Support.Message>()

   useEffect(() => {
      if (orderId) {
         fetchChat()
      }
   }, [])

   useEffect(() => {
      if (chat != null && messages.length === 0) {
         fetchMessages()
      }
   }, [chat])

   async function fetchChat() {

      console.log('Fetching Chat ...')
      setShowLoader(true)
      try {
         const response = await Support.getChatsForOrder(orderId)
         setChat(response.data[0])
      } catch (error) {
         console.error('Error Getting Chat', error)
         Alert.alert('Error Getting Chat', String(error))
      }
      setShowLoader(false)
   }

   async function fetchMessages() {
      if (chat?.id == null) return

      console.log('Fetching Messages ...')
      setShowLoader(true)
      try {
         const response = await Support.getMessagesForChat(orderId, chat.id)
         setMessages(response.data)
      } catch (error) {
         console.error('Error Getting Messages', error)
         Alert.alert('Error Getting Messages', String(error))
      }
      setShowLoader(false)

      try {

         const userId = firestar.getUserId()
         await Support.markAsRead(orderId, userId ?? '')
      } catch (error) {

      }
   }

   return (
      <View style={{
         padding: 15,
         width: 600
      }}>
         <Backdrop open={showLoader}><Progress /></Backdrop>
         <View style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
         }}>
            <h2>Open Chat</h2>
			   <Icon
				   size={40}
				   onPress={onClickClose}>
				   <CloseIcon />
			   </Icon>
         </View>
         <View style={{
            flexDirection:'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginVertical: 10
         }}>
            <View>
               <Text style={{
                  marginVertical: 2
               }}>{chat?.createdBy.displayName ?? 'No Name Found'}</Text>
               <Text style={{
                  marginVertical: 2
               }}>{chat?.createdBy.email}</Text>
               <Text style={{
                  marginVertical: 2
               }}>Title: {chat?.title}</Text>
               {chat?.subject != null && <Text style={{
                  marginVertical: 2
               }}>Subject: {SupportOptions[chat?.subject]}</Text>}
            </View>
            <Button 
               titleStyle={{
                  fontSize: 12
               }}
               onPress={fetchMessages}
               title='REFRESH'/>
         </View>
         <Paper style={{
            padding: 15
         }}>
            <FlatList
               style={{
                  minHeight: window.innerHeight * 0.6
               }}
               ListEmptyComponent={() => (
                  <View>
                     <Text>No Messages</Text>
                  </View>
               )}
               data={messages}
               renderItem={renderItem} />
         </Paper>
         <View style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginVertical: 10
         }}>
            <TextField
               style={{
                  flex: 1,
                  marginRight: 10
               }}
               value={newMessage?.message ?? ''}
               onChangeText={(value) => {
                  onChangeMessage('message', value)
               }} />
            <Button
               containerStyle={{
                  width: 150
               }}
               onPress={onClickSubmit}
               title='Send' />
         </View>
      </View>
   )

   function renderItem({ item }: { item: Support.Message }) {
      const dateString = moment(item.createdAt?.toDate()).format('hh:mm A, DD-MMM-YY')
      const name = item.createdBy.displayName ?? item.createdBy.email ?? item.createdBy.phoneNumber ?? item.createdBy.uid
      const colorCode = item.createdBy.uid ?? ''
      return (
         <View style={{
            marginBottom: 10,
         }}>
            <Text style={{
               padding: 15,
               backgroundColor: item.createdBy.uid == firestar.getUserId() ? '#5eb6e6' : stringToColour(colorCode),
               borderRadius: 30,
               color: item.createdBy.uid == firestar.getUserId() ? 'white' : 'white',
               fontSize: 14,
               marginVertical: 5,
               flex: 1,
               alignSelf: item.createdBy.uid == firestar.getUserId() ? 'flex-end' : 'flex-start',
            }}>{item.message}</Text>
            <View style={{
               flexDirection: 'row',
               alignSelf: item.createdBy.uid == firestar.getUserId() ? 'flex-end' : 'flex-start',
               alignItems: 'center'
            }}>
               <Text style={{
                  alignSelf: item.createdBy.uid == firestar.getLoginId() ? 'flex-end' : 'flex-start',
                  fontSize: 12,
                  marginTop: 1
               }}>{dateString}</Text>
               <Text> • {name}</Text>
            </View>        
         </View>
      )
   }

   function onChangeMessage(path: string, value: any) {
      const __newMessage = { ...newMessage } as Support.Message
      lodash.set(__newMessage, path, value)
      setNewMessage(__newMessage)
   }

   async function onClickSubmit() {
      try {
         const response=  await Support.sendMessageForOrder(orderId,chat!.id,newMessage!)
         const __messages = [...messages]
         __messages.push(response as Support.Message)
         setMessages(__messages)
         setNewMessage(undefined)
      } catch (error) {
         Alert.alert('Error Sending Message',String(error))
         console.log('Error Sending Message : ',error)
      }
   }
}

const stringToColour = function (str) {
   var hash = 0;
   for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
   }
   var colour = '#';
   for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xFF;
      colour += ('00' + value.toString(16)).substr(-2);
   }
   return colour;
}