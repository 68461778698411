"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const firestar_1 = require("firestar");
var Vouchers;
(function (Vouchers) {
    function getVouchers(casinoId, promotionId, descriptor) {
        return firestar_1.Actions.readCollection(`casinos/${casinoId}/promotions/${promotionId}/vouchers`, descriptor);
    }
    Vouchers.getVouchers = getVouchers;
    function getVoucherInfo(voucher) {
        return {
            id: voucher.id,
            name: voucher.name
        };
    }
    Vouchers.getVoucherInfo = getVoucherInfo;
    function update(casinoId, promotionId, voucherId, document) {
        return firestar_1.Actions.updateDocument(`casinos/${casinoId}/promotions/${promotionId}/vouchers/${voucherId}`, document);
    }
    Vouchers.update = update;
    function deleteVoucher(casinoId, promotionId, voucherId) {
        return firestar_1.Actions.deleteDocument(`casinos/${casinoId}/promotions/${promotionId}/vouchers/${voucherId}`);
    }
    Vouchers.deleteVoucher = deleteVoucher;
    function createVoucher(casinoId, promotionId, document, options) {
        return firestar_1.Actions.addToCollection(`casinos/${casinoId}/promotions/${promotionId}/vouchers`, document, options);
    }
    Vouchers.createVoucher = createVoucher;
    function getVoucher(voucherId, readOptions) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await firestar_1.Actions.readCollectionGroup('vouchers', {
                    where: [
                        {
                            fieldPath: 'id',
                            opStr: '==',
                            value: voucherId
                        }
                    ]
                }, readOptions);
                if (response.data.length === 1) {
                    resolve(response.data[0]);
                }
                else {
                    resolve(undefined);
                }
            }
            catch (error) {
                reject(error);
            }
        });
    }
    Vouchers.getVoucher = getVoucher;
    function getVoucherForPromotion(casinoId, promotionId, descriptor) {
        return firestar_1.Actions.readCollection(`casinos/${casinoId}/promotions/${promotionId}/vouchers`, descriptor);
    }
    Vouchers.getVoucherForPromotion = getVoucherForPromotion;
})(Vouchers || (Vouchers = {}));
exports.default = Vouchers;
