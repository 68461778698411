import React, { useState, useEffect, useRef } from 'react'
import { View, Text } from 'react-native'
import MaterialTable from 'material-table'
import Users from 'cs-connect/lib/Users'
import { Alert } from './utils/Alert'
import {
	WhereType
} from 'firestar'
import { useDebounce } from './utils'
import MessageIcon from '@material-ui/icons/Message'
import ButtonBase from '@material-ui/core/ButtonBase'
import Modal from '@material-ui/core/Modal'
import SendMessage from './components/SendMessage'

// import firebase from 'firebase/compat/app'
// import {
// 	getDiff,
// 	flattenObject,
// } from 'firestar/lib/Utils'
// import lodash from 'lodash'

//commented for updated user code below

const INITIAL_PAGESIZE = 15
const PAGESIZE = 10

export default function UsersList() {

	const [users, setUsers] = useState<Users.User[]>([])
	const [showLoader, setShowLoader] = useState(false)
	const [searchText, setSearchText] = useState('')
	const debouncedSearchTerm = useDebounce(searchText, 200);
	const tableRef = useRef<any>()
	const [sendMessage, setSendMessage] = useState<Users.User>()

	useEffect(
		() => {
			// Make sure we have a value (user has entered something in input)
			if (debouncedSearchTerm.length > 0) {
				// Set isSearching state
				//  setIsSearching(true);
				//  // Fire off our API call
				//  searchCharacters(debouncedSearchTerm).then(results => {
				// 	// Set back to false since request finished
				// 	setIsSearching(false);
				// 	// Set results state
				// 	setResults(results);
				//  });
				fetchUsers(debouncedSearchTerm)
			} else {
				fetchUsers()
			}
		},
		// This is the useEffect input array
		// Our useEffect function will only execute if this value changes ...
		// ... and thanks to our hook it will only change if the original ...
		// value (searchTerm) hasn't changed for more than 500ms.
		[debouncedSearchTerm]
	);

	useEffect(() => {
		fetchUsers()
	}, [])

	async function fetchUsers(searchTerm?: string) {

		setShowLoader(true)
		try {

			const whereTypes: WhereType[] = []

			if (searchTerm != null && searchTerm.length > 0) {
				whereTypes.push({
					fieldPath: 'tags',
					opStr: 'array-contains-any',
					value: searchTerm?.toLowerCase().trim().split(' ').splice(0, 10)
				})
			}

			const response = await Users.getUsers({
				where: whereTypes,
				limit: INITIAL_PAGESIZE,
				orderBys: [
					{
						fieldPath: 'updatedAt',
						directionStr: 'desc'
					}
				]
			})

			setUsers(response.data.filter(({ email, phoneNumber }) => email != null || phoneNumber != null))
		} catch (error) {
			console.log('Error Getting Users ', error)
			Alert.alert(
				'Error Getting Users',
				JSON.stringify(error)
			)
		}
		setShowLoader(false)
	}

	return (
		<View style={{
			padding: 15
		}}>
			<Modal style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}} open={sendMessage != null}>
				<SendMessage onCancel={onCancelSendMessage} user={sendMessage}/>
			</Modal>
			<MaterialTable
				tableRef={(ref) => tableRef.current = ref}
				isLoading={showLoader}
				title='Users'
				data={users}
				// editable={{
				//    onRowUpdate: onRowUpdate
				// }}
				onSearchChange={(text) => {
					setSearchText(text)
				}}
				options={{
					search: true,
					pageSizeOptions: [PAGESIZE],
					pageSize: PAGESIZE,
					searchText: searchText,
					searchFieldVariant: 'outlined',
					searchFieldStyle: {
						margin: '1em'
					},
					filtering: false
				}}
				columns={[
					{
						field: 'id',
						title: 'ID',
						editable: 'never',
						headerStyle: {
							fontWeight: 'bold'
						},
						cellStyle: {
							fontSize: 12
						},
					},
					{
						field: 'displayName',
						title: 'Name',
						editable: 'onUpdate',
						headerStyle: { fontWeight: 'bold' }
					},
					{
						field: 'phoneNumber',
						title: 'Phone Number',
						editable: 'onUpdate',
						headerStyle: { fontWeight: 'bold' }
					},
					{
						field: 'email',
						title: 'Email',
						editable: 'onUpdate',
						headerStyle: { fontWeight: 'bold' }
					},
					{
						field: 'token',
						title: 'Notification',
						editable: 'never',
						headerStyle: { fontWeight: 'bold' },
						render: (c) => (
							<div style={{
								display: 'flex',
								justifyContent: 'flex-start',
								alignItems: 'center'
							}}>
								<label style={{
									backgroundColor: c.token ?? ''.length > 0 ? 'green' : 'gray',
									color: 'white',
									padding: '0.2em 0.5em',
									borderRadius: 5,
									fontSize: 12,
									marginRight: '1em'
								}}>{c.token ?? ''.length > 0 ? 'Available' : 'Unavailable'}</label>
								{(c.token ?? '').length > 0 && (
									<ButtonBase onClick={() => onClickSendMessage(c)}>
										<MessageIcon />
									</ButtonBase>
								)}
							</div>
						)
					},
					{
						field: 'updatedAt',
						title: 'Updated On',
						editable: 'never',
						headerStyle: { fontWeight: 'bold' },
						render: (c) => (
							<Text>{c.createdAt?.toDate?.().toDateString()}</Text>
						)
					}
				]} />
			<br />
		</View>
	)

	function onCancelSendMessage(){
		setSendMessage(undefined)
	}

	function onClickSendMessage(user: Users.User){
		setSendMessage(user)
	}

	//    function onRowUpdate(newUser: Users.User){
	//       return new Promise<void>(async (resolve, reject) => {
	//          try {

	//             const changeData = {} as Users.User
	//             tableRef.current.dataManager.columns.filter(m => m.editable === 'always' || m.editable === 'onUpdate')
	//                .forEach(m => {
	//                   lodash.set(changeData, m.field ?? '', lodash.get(newUser, m.field ?? '') ?? null)
	//                })

	//             const __users = [...users]
	//             const findIndex = __users.findIndex(m => m.id === newUser.id)
	//             const oldUser = __users[findIndex]
	//             const changes = getDiff(changeData, oldUser) as Partial<Users.User>
	//             // const flattenedChanges = flattenObject(changes)

	//             const userRecord = await Users.updateUserAccount(newUser.id, {
	//                displayName: changes.displayName,
	//                email: changes.email,
	//                phoneNumber: changes.phoneNumber,
	//             })
	//             const userInfo = Users.getUserInfo(userRecord)

	//             __users[findIndex] = {
	//                ...oldUser,
	//                ...userInfo
	//             }

	//             setUsers(__users)

	//             resolve()
	//          } catch (error) {

	//             console.log('Error Updating User', error)
	//             reject(error)
	//          }
	//       })
	//    }
}
