import { View, ActivityIndicator } from 'react-native'

export default function Progress(props:{
   title?: string,
   description?: string
}) {
   return (
      <View style={{
         flex: 1,
         justifyContent: 'center',
         alignItems: 'center',
		 height: '100vh'
      }}>
         <ActivityIndicator />
         <div>
            {props.title != null && <h3>{props.title}</h3>}
            {props.description != null && <p>{props.description}</p>}
         </div>
      </View>
   )
}
