import './firebase'
import {
	BrowserRouter as Router,
	Switch,
	Route,
} from 'react-router-dom';
import AuthContextProvider from "./containers/AuthContextProvider";
import UserContextProvider from "./containers/UserContextProvider";
import Home from './Home'

function App() {
	return (
		<Switch>
			<Route path='/' component={Home} />
		</Switch>
	)
}

const AppContainer = () => (
	<Router>
		<AuthContextProvider>
			<UserContextProvider>
				<App />
			</UserContextProvider>
		</AuthContextProvider>
	</Router>
)

export default AppContainer