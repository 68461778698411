import React, { useState } from 'react'
import Image from './res/gaming.png'
import Modal from '@material-ui/core/Modal'

export default function Gaming() {

	const [show, setShow] = useState(false)

	return (
		<div style={{
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			minHeight: '100vh',
			background: 'black',
			flexDirection: 'column'
		}}>
			{/* <button onClick={() => {
				setShow(!show)
			}}>Show/Hide</button> */}
			<br />
			<div onClick={() => {
				setShow(!show)
			}} style={{
				display: 'flex',
				height: 540,
				width: 961,
				justifyContent: 'center',
				alignItems: 'center',
				border: '2px solid white',
				cursor: 'pointer'
			}}>
				<img src={Image} alt='' style={{
					height: 540,
					width: 961,
					position: 'absolute',
					zIndex: 0
				}}/>
				{/* {show && ( */}
					<div style={{
						width: '100%',
						height: '100%',
						zIndex: 1,
						padding: '1em 1em',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						opacity: show ? 1 : 0,
						transform: show ? 'scale(1)' : 'scale(0)',
						transition: 'opacity 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55), transform 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55)',
						background: show ? '#11111199' : undefined,
					}}>
						<div style={{
							background: 'black',
							width: '90%',
							height: '90%',
							borderRadius: '1.5em',
							border: '2px solid yellow',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}>
							<div style={{
								background: 'black',
								width: '98%',
								height: '97%',
								borderRadius: '1em',
								border: '2px solid yellow',
								overflow: 'hidden'
						}}>
							<iframe
								frameBorder={0}
								title={'game'}
								style={{ 
									width: '100%',
									height: '100%'
								 }}
								src={`https://app.infigifts.com/casinos/ice5DjyWQECntSvnjC5m/promotions/scfVqR5xyvoxo2pEuioa?skipAuth=true&cardNumber=1&isDev=false`} />
						</div>
					</div>
				</div>
				{/* )} */}
			</div>
			{/* <img onClick={() => {
				setShow(true)
			}} alt='f' style={{
				border: '5px solid white',
				borderRadius: 5
			}} src={Image} />
			 */}
		</div>
	)
}
