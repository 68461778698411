import React, { useEffect, useState } from 'react'
import { View, Text, Image, ActivityIndicator } from 'react-native'
import MaterialTable from 'material-table'
import {
   Members,
   Orders,
   Users
} from 'cs-connect';
import { OrderStatusOptions, OrderTypeOptions } from 'cs-connect/lib/Orders';
import CheckBox from './components/CheckBox'
import SearchBar from './components/SearchBar'
import Icon from './components/Icon'
import Button from './components/Button'
import Badge from './components/Badge'
import { Alert } from './utils/Alert.web';
import {
   WhereType,
   OrderType
} from 'firestar'
import moment from 'moment';
import JSONView from 'react-json-view';
import Chats from './Chats';
import Dialog from '@mui/material/Dialog';
import lodash from 'lodash';
import { imageForProduct, useDebounce } from './utils';
import { useHistory, useParams } from 'react-router-dom';
import {
   getFirestore,
   collection,
   getCountFromServer
} from 'firebase/firestore'
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser'
import ForumIcon from '@material-ui/icons/Forum'
import Gamepad from '@material-ui/icons/Gamepad'

var OrderColors: { [key: string]: string } = {
   'failed': 'red',
   'created': '#3468eb',
   'cancelled': 'red',
   'confirmed': 'green',
   'shipped': 'green'
};


const INITIAL_PAGESIZE = 15
const PAGESIZE = 10

const SortOptions = {
   'createdAt': 'Created At',
   'updatedAt': 'Updated At',
   'chats.updatedAt' : 'Updated At (Support)',
   'chats.unread.support' : 'Unread (Support)',
   'lastSyncedAt' : 'Last Synced Time'
}

export default function OrdersList() {

   const [orders, setOrders] = useState<Orders.Order[]>([])
   const history = useHistory()
   const params = useParams<{
      id: string
   }>()
   const [showLoader, setShowLoader] = useState(false)
   const [lastDoc, setLastDoc] = useState<any>()
   const [searchText, setSearchText] = useState(params?.id ?? '')
   const debouncedSearchTerm = useDebounce(searchText, 500);
   const [exactSearch, setExactSearch] = useState(false)
   const [statusFilter, setStatusFilter] = useState<undefined | number>()
   const [orderByFilter, setOrderByFilter] = useState(0)
   const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('desc')
   const [showChat, setShowChat] = useState<string>()
   const [totalCount, setTotalCount] = useState<number>()

   useEffect(() => {
      fetchOrders()
   }, [])

   useEffect(
      () => {
         // Make sure we have a value (user has entered something in input)
         if (debouncedSearchTerm) {
            // Set isSearching state
            //  setIsSearching(true);
            //  // Fire off our API call
            //  searchCharacters(debouncedSearchTerm).then(results => {
            // 	// Set back to false since request finished
            // 	setIsSearching(false);
            // 	// Set results state
            // 	setResults(results);
            //  });
            fetchOrders(undefined, debouncedSearchTerm)
         } else {
            fetchOrders()
         }
      },
      // This is the useEffect input array
      // Our useEffect function will only execute if this value changes ...
      // ... and thanks to our hook it will only change if the original ...
      // value (searchTerm) hasn't changed for more than 500ms.
      [debouncedSearchTerm, exactSearch, statusFilter, orderByFilter, orderDirection]
   );


   async function fetchOrders(paginating?: boolean, searchTerm?: string) {
      const __isPaginating = paginating ?? false
      setShowLoader(true)
      try {

         const searchTags = (searchTerm ?? '').split(' ')
                              .map((text: string) => text.toLowerCase().trim())
                              .filter((text: string) => text.length > 0)

         let where: WhereType[] = []
         let orderBy: OrderType | undefined

         if(statusFilter != null){
            where.push({
               fieldPath: 'status',
               opStr: '==',
               value: Object.keys(OrderStatusOptions)[statusFilter]
            })
         }

         if (orderByFilter != null && exactSearch === false) {
            orderBy = {
               fieldPath: Object.keys(SortOptions)[orderByFilter],
               directionStr: orderDirection
            }
         }

         const path = `orders`
         const coll = collection(getFirestore(), path);
         const snapshot = await getCountFromServer(coll);
         setTotalCount(snapshot.data().count)

         if (searchTags.length > 0) {
            if (exactSearch === true) {
               where = where.concat(searchTags.map((searchTag) => {
                  return {
                     fieldPath: `matches.${searchTag}`,
                     value: true,
                     opStr: '=='
                  }
               }))
            } else {
               where = where.concat([
                  {
                     fieldPath: 'tags',
                     opStr: 'array-contains-any',
                     value: searchTags
                  }
               ])
            }
         }

         if(searchTerm?.startsWith('$')){
            const searchComps = searchTerm.split('==')
            if(searchComps.length === 2){
               const key = searchComps[0]
               const value = searchComps[1]
               where = [{
                  fieldPath: key.trim().replace('$', ''),
                  opStr: '==',
                  value: value.trim()
               }]
            }
         }

         // console.log('Filters : ',where)
         // console.log('Order : ', orderBy)

         const response = await Orders.getOrders({
            limit: __isPaginating === true ? PAGESIZE : INITIAL_PAGESIZE,
            lastDoc: __isPaginating === true ? lastDoc : undefined,
            where: where,
            orderBy: orderBy
         })
         setLastDoc(response.lastDoc)
         if (__isPaginating === true) {
            setOrders([...orders, ...response.data])
         } else {
            setOrders(response.data)
         }

      } catch (error) {
         Alert.alert('Error Fetching Orders', String(error))
         console.log('Error Fetching Orders : ', error)
      }
      setShowLoader(false)
   }

   return (
      <View style={{
         padding: 15
      }}>
         <Dialog open={showChat != null}>
            <View>
               <Chats 
                  onClickClose={() => setShowChat(undefined)}
                  orderId={showChat ?? ''}/>
            </View>
         </Dialog>
         <View style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginVertical: 10
         }}>
            <Button
               containerStyle={{
                  width: 150
               }}
               disabled={showLoader == true}
               onPress={async () => {

                  setShowLoader(true)
                  try {
                     await updateActiveOrders()
                  } catch (error) {
                     
                  }
                  setShowLoader(false)
               }}
               title='Update Orders' />
            <Button
               containerStyle={{
                  width: 150
               }}
               disabled={showLoader == true}
               onPress={onPressRefresh}
               type='outlined'
               title='Refresh' />
         </View>
         <View style={{
            paddingVertical: 15,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            flex: 1
         }}>
            <Text style={{
               fontSize: 22
            }}></Text>
            <View>
               <View style={{
                  flexDirection: 'row',
                  alignItems: 'center',
               }}>
                  <CheckBox
                     containerStyle={{
                        backgroundColor: 'white',
                        borderWidth: 0,
                        borderBottomWidth: 0,
                        borderTopWidth: 0
                     }}
                     // disabled={searchText.length == 0}
                     title={'Exact Search'}
                     onPress={() => setExactSearch(!exactSearch)}
                     checked={exactSearch} />
                  <SearchBar
                     containerStyle={{
                        backgroundColor: 'white',
                        Padding: '0em 1em',
                        flex: 1,
                     }}
                     inputStyle={{
                        color: 'black',
						width: 400
                     }}
                     onClear={() => {
                        if(params.id){
                           history.replace('/orders')
                        }else{
                           setSearchText('')
                        }
                     }}
                     placeholder='Search'
                     onChangeText={(text) => setSearchText(text)}
                     value={searchText} />
               </View>
               <View style={{
                  marginVertical: 15,
                  flexDirection: 'row',
                  justifyContent: 'flex-end'
               }}>
                  <div>
                     <select style={{
                        padding: 15,
                        fontSize: 16,
                        borderWidth: 0,
                        marginRight: 15,
                        textAlign: 'right',
                        background: 'transparent',
                        width: 210
                     }} value={statusFilter} onChange={onChangeStatus}>
                        <option value='all'>Status: Any</option>
                        {Object.keys(OrderStatusOptions).map((key, index) => (
                           <option value={index}>Status: {OrderStatusOptions[key]}</option>
                        ))}
                     </select>
                  </div>
                  {exactSearch == false && <div>
                     <select style={{
                        padding: 15,
                        fontSize: 16,
                        borderWidth: 0,
                        marginRight: 15,
                        textAlign: 'right',
                        background: 'transparent',
                        width: 300
                     }} value={orderByFilter} onChange={onChangeSort}>
                        {Object.keys(SortOptions).map((key, index) => (
                           <option value={index}>Sort By: {SortOptions[key]}</option>
                        ))}
                     </select>
                  </div>}
               </View>
            </View>
         </View>
         <MaterialTable
            isLoading={showLoader}
            title='Orders'
            data={orders}
            options={{
               pageSizeOptions: [PAGESIZE],
               pageSize: PAGESIZE,
               search: false,
               searchFieldVariant: 'outlined',
               searchFieldStyle: {
                  marginTop: 15,
                  marginRight: 10
               },
            }}
            editable={{
               onRowDelete: onOrderDelete
            }}
            detailPanel={renderDetail}
            onOrderChange={onOrderChange}
            onChangePage={onChangePage}
            columns={[
               {
                  field: 'id',
                  title: 'Order ID',
                  editable: 'never',
                  headerStyle: {
                     fontWeight: 'bold'
                  },
                  sorting: false,
                  cellStyle: {
                     fontSize: 12
                  },
                  render: (order) => {
                     return (
                        <View style={{
                           flexDirection: 'column'
                        }}>
                           <View>
                              <Text>{order.id}</Text>
                              <Text>{order.metadata?.name} {order.type && <Text style={{
                                 color: 'brown',
                                 fontWeight: 'bold'
                              }}>• {OrderTypeOptions[order.type]}</Text>}</Text>                              
                           </View>
                           <Icon onPress={() => {
								 const url = `https://casino-science.myshopify.com/admin/orders/${order.metadata.id}`
								 window.open(url, '_blank')
							 }}>
								 <OpenInBrowserIcon />
							  </Icon>
                        </View>
                     )
                  }
               },
               {
                  field: 'promotionInfo.id',
                  title: 'Promotion',
                  editable: 'never',
                  headerStyle: {
                     fontWeight: 'bold'
                  },
                  sorting: false,
                  cellStyle: {
                     fontSize: 12
                  },
                  render: (order) => {
                     return (
                        <View style={{
                           flexDirection: 'column'
                        }}>
                           <Text>{order.promotionInfo?.name}</Text>
                           <Text>{order.promotionInfo?.id}</Text>
                        </View>
                     )
                  }
               },
               {
                  title: 'Gift',
                  searchable: false,
                  editable: 'never',
                  headerStyle: {
                     fontWeight: 'bold'
                  },
                  sorting: false,
                  cellStyle: {
                     fontSize: 12
                  },
                  render: (order) => {

                     const product = order.product
                     let thumbnail = imageForProduct(product)
                     return (
                        <View style={{
                           flexDirection: 'row',
                           alignItems: 'center'
                        }}>
                           <Image
                              style={{
                                 width: 40,
                                 height: 40,
                                 marginRight: 10
                              }}
                              source={{
                                 uri: thumbnail,
                                 cache: 'force-cache'
                              }} />
                           <Text>{order.product.title}</Text>
                           <Icon onPress={() => {
                              const url = `https://casino-science.myshopify.com/admin/products/${order.product.id}`
                              window.open(url, '_blank')
                           }}>
								<OpenInBrowserIcon/>
						   </Icon>
                        </View>
                     )
                  },
               },
               {
                  field: 'id',
                  title: 'Casino',
                  editable: 'never',
                  headerStyle: {
                     fontWeight: 'bold'
                  },
                  sorting: false,
                  render: (order) => (
                     <View>
                        <Text style={{
                           fontWeight: 'bold',
                           fontSize: 15
                        }}>{order.casinoInfo.name}</Text>
                        <Text style={{
                           fontSize: 10
                        }}>{order.casinoInfo.id}</Text>
                     </View>
                  ),
                  cellStyle: {
                     fontSize: 12
                  }
               },
               {
                  field: 'id',
                  title: 'User',
                  editable: 'never',
                  headerStyle: {
                     fontWeight: 'bold'
                  },
                  sorting: false,
                  render: (order) => (
                     <View>
                        <Text style={{
                           fontWeight: 'bold'
                        }}>{order.userInfo.displayName ?? order.member.name ?? 'No Name'}</Text>
                        {order.userInfo.email != null && <Text style={{
                           fontSize: 12,
                        }}>{order.userInfo.email}</Text>}
                        {order.userInfo.phoneNumber != null && <Text style={{
                           fontSize: 12,
                        }}>{order.userInfo.phoneNumber}</Text>}
                        <Text style={{
                           fontSize: 10,
                        }}>{order.userInfo.uid}</Text>
                     </View>
                  ),
                  cellStyle: {
                     fontSize: 12
                  }
               },
               {
                  title: 'Status',
                  editable: 'never',
                  headerStyle: {
                     fontWeight: 'bold'
                  },
                  sorting: false,
                  render: (order) => {

                     const link = order.overrideTrackingLink ?? order.trackingLink
                     let status = order.status as any
                     if(order.metadata?.name == null && order.status === 'created'){
                        return <div style={{
                           color: '#a834eb',
                           fontWeight: 'bold'
                        }}>Pending...</div>
                     }
                     return (
                        <View>
                           <Text style={{
                              color: OrderColors[status],
                              fontWeight: 'bold'
                           }}>{OrderStatusOptions[status]}</Text>
                           {link && (
                              <a rel="noreferrer" target='_blank' href={link}>Track</a>
                           )}
                        </View>
                     )
                  }
               },
               {
                  title: 'Support',
                  editable: 'never',
                  headerStyle: {
                     fontWeight: 'bold'
                  },
                  sorting: false,
                  render: (order) => (
                     <View>
                        {(order.chats?.unread?.support ?? 0) > 0 && (
                           <Badge style={{
                              position: 'absolute',
                              right: 10,
                              top: -20
                           }} value={order.chats?.unread?.support} />
                        )}
                        {(order.chats?.open ?? 0) > 0 && (
                           <Icon onPress={() => {
                              setShowChat(order.id)
                           }}>
								<ForumIcon/>
						   </Icon>
                        )}
                     </View>
                  )
               },
               {
                  title: Object.values(SortOptions)[orderByFilter],
                  headerStyle: {
                     fontWeight: 'bold'
                  },
                  sorting: true,
                  defaultSort: orderDirection,
                  render: (order) => {
                     const date = order[Object.keys(SortOptions)[orderByFilter] as string]
                     const dateString = moment(date?.toDate()).format('hh:mm A, DD-MMM-YY')
                     return (
                        <View style={{
                           display: 'flex',
                           alignItems: 'center',
                           justifyContent: "center"
                        }}>
                           {date != null && <Text style={{
                              fontSize: 12
                           }}>{moment(date.toDate()).fromNow()}</Text>}
                           <Text>{dateString}</Text>
                        </View>
                     )
                  },
                  align: 'center'
               },
               {
                  field: 'id',
                  editable: 'never',
                  title: '',
                  render: (m) => (
                     <View style={{
                        flexDirection: 'row',
                        alignItems: 'center'
                     }}>
                        <Icon
                           onPress={() => window.open('https://admin.infigifts.com/playground/orders/' + m.id)}>
							<Gamepad/>
						   </Icon>
                     </View>
                  ),
                  sorting: true,
               }
            ]} />
            <div style={{
               textAlign: 'right',
               marginTop: 5
            }}>Total Orders: {totalCount ?? '--'}</div>
      </View>
   )

   function onOrderDelete(order: Orders.Order) {
      return new Promise<void>(async (resolve, reject) => {
         try {
            await Orders.deleteOrder(order.id)
            const __orders = [...orders]
            const findIndex = __orders.findIndex((o) => o.id === order.id)
            if(findIndex >= 0){
               __orders.splice(findIndex, 1)
            }
            setOrders(__orders)
            resolve()
         } catch (error) {
            reject()
            console.log('Error deleting order', error)
            Alert.alert('Error Deleting Order', String(error))
         }
      })
   }

   function onChangePage(page: number, pageSize: number) {
      if (orders.length / pageSize / page <= (INITIAL_PAGESIZE / PAGESIZE)) {
         if (lastDoc != null) fetchOrders(true, searchText)
      }
   }

   function onChangeSort(event) {
      setOrderByFilter(event.target.value)
   }

   function onChangeStatus(event) {
      if (event.target.value === 'all') {
         setStatusFilter(undefined)
      } else {
         setStatusFilter(event.target.value)
      }
   }

   function onOrderChange(_: number, orderDirection: "asc" | "desc") {
      if (orderDirection.length > 0) {
         setOrderDirection(orderDirection)
      } else {
         setOrderDirection('asc')
      }
   }

   async function onPressRefresh() {

      if (searchText.length > 0) {
         setSearchText('')
      } else {
         fetchOrders()
      }
   }

   function renderDetail(order: Orders.Order){
      return (
         <DetailPanel 
            onUpdateOrder={onUpdateOrder}
            order={order}/>
      )
   }

   function onUpdateOrder(order: Orders.Order) {
      const index = orders.findIndex((o) => order.id === o.id)
      if (index >= 0) {
         const __orders = [...orders]
         __orders[index] = order
         setOrders(__orders)
      }
   }
}

function DetailPanel(props: {
   order: Orders.Order,
   onUpdateOrder: (order: Orders.Order) => void
}) {

   const [showLoader, setShowLoader] = useState(false)
   const { onUpdateOrder } = props
   const [order, setOrder] = useState(lodash.cloneDeep(props.order))
   const [overrideLink, setOverrideLink] = useState(order.overrideTrackingLink ?? '')

   if(showLoader === true){
      return (
         <div>
            <br/>
            <ActivityIndicator/>
            <br/>
         </div>
      )
   }

   return (
      <div style={{
         padding: 15
      }}>
         {order.failureError != null && (
            <div>
               <p><b>Reason:</b><span>{order.failureError.name}</span></p>
               <p><b>Error:</b><span>{JSON.stringify(order.failureError.error)}</span></p>
               {order.metadata?.name == null && order.status === 'failed' && <div>
                  <b>Order Pending</b>&nbsp;&nbsp;<button onClick={() => onClickRetry()}>&nbsp;&nbsp;&nbsp;Retry&nbsp;&nbsp;&nbsp;</button>
               </div>}
               <p>Order Document</p>
                  <JSONView
                     collapsed={true}
                     sortKeys
                     name='Order'
                     style={{
                        fontSize: 18,
                        flexWrap: 'wrap',
                        wordWrap: "break-word",
                        textAlign: 'left'
                     }}
                     src={order} />
            </div>
         )}
         <br/>
         <hr/>
         <br/>
         {(order.isShippingRequired ?? true) && <div>
            <b><label>Tracking Link</label></b>
            <div>
               <div>
                  <input
                     style={{ width: 500 }}
                     value={overrideLink}
                     onChange={(event) => {
                        setOverrideLink(event.target.value.trim())
                     }} />
                  &nbsp;&nbsp;
                  <button onClick={onPressSaveLink}>Save</button>
                  &nbsp;&nbsp;
                  {order.overrideTrackingLink && <a target='_blank' style={{
                     textDecoration: 'none'
                  }} href={order.overrideTrackingLink} rel="noreferrer">Open Link →</a>}
               </div>
               <br/>
               {order.trackingLink && <a target='_blank' style={{
                  textDecoration: 'none'
               }} href={order.trackingLink} rel="noreferrer">Auto Generated Tracking Link →</a>}
               {order.metadata?.order_status_url && <div style={{
                  marginTop: '0.5em'
               }}>
                  <a target='_blank' style={{
                     textDecoration: 'none',
                  }} href={order.metadata?.order_status_url} rel="noreferrer">Shopify Order Link →</a>
               </div>}
            </div>
         </div>}
         <br/>
         <div>
            <div><b>Last Synced At:</b> {moment(order.lastSyncedAt?.toDate()).format('hh:mm A • DD MMM YYYY')}</div>
			{order.dev && <>
				{order.dev.createdAt && <div><b>Order Created At:</b> {moment(order.dev.createdAt?.toDate()).format('hh:mm:ss A • DD MMM YYYY')}</div>}
				<br />
				{order.dev.executionTime && <div><b>Execution time taken:</b> {(order.dev.executionTime / 1000).toFixed(1)} secs</div>}
				{order.dev.endedAt && <div><b>Execution end at:</b> {moment(order.dev.endedAt?.toDate()).format('hh:mm:ss A • DD MMM YYYY')}</div>}
				{order.dev.endedAt && order.dev.createdAt && order.dev.executionTime && <div><b>Waiting time:</b>  {((order.dev.endedAt.toDate().getTime() - order.dev.createdAt.toDate().getTime() - order.dev.executionTime)/1000).toFixed(1)} secs</div>}
				{order.dev.endedAt && order.dev.createdAt && <div><b>Total time:</b>  {((order.dev.endedAt.toDate().getTime() - order.dev.createdAt.toDate().getTime())/1000).toFixed(1)} secs</div>}
				<br />
			</>}
            {order.author && (
               <div><b>Author:</b> {order.author.displayName&&<span>{order.author.displayName} • </span>}{order.author.email}</div>
            )}
            <div><b>Comments:</b> {order.comments ?? '--'}</div>
         </div>
         <br/>
         <button onClick={async () => {
            setShowLoader(true)
            try {
               await updateActiveOrders([order.id])
               const response = await Orders.getOrder(order.id)
               setOrder(response)
               onUpdateOrder(response)
            } catch (error) {
               
            }
            setShowLoader(false)
         }}>Sync With Shopify</button>
         {/* <br/>
         <br/>
         <button onClick={async () => {
            const response = await Orders.getShopifyOrder(order.metadata.id)
            const __order = lodash.cloneDeep({
               ...order,
               metadata: response
            }) as Orders.Order
            Orders.compileOrderMetadata(__order)
            console.log('Comppiled Status:', __order.status)
            Alert.alert(
               'Compiled Order Status',
               __order.status,
               [
                  {
                     onPress: () => {
                        window.open('https://us-central1-continuity-gifting-app.cloudfunctions.net/getShopifyOrder?id=' + order.metadata.id)
                     },
                     text: 'Open Metadata'
                  },
                  {
                     text: 'Close'
                  }
               ]
            )
         }}>Open Shopify Order Metadata</button> */}
         <p>Metadata</p>
         <JSONView
            collapsed={true}
            sortKeys
            name='metadata'
            style={{
               fontSize: 18,
               flexWrap: 'wrap',
               wordWrap: "break-word",
               textAlign: 'left'
            }}
            src={order.metadata} />
      </div>
   )

   async function onClickRetry() {

      Alert.alert(
         'Warning',
         'This action will delete the existing order and create a new order with the same id and information. Would you like to continue? The page will refresh after this operation.',
         [
            {
               onPress: onConfirmRetry,
               style: 'destructive',
               text: 'Confirm'
            },
            {
               style: 'cancel',
               text: 'Cancel'
            }
         ]
      )
   }

   async function onConfirmRetry(){
      setShowLoader(true)
      try {
         console.log('Order', order)

         const casinoId = order.casinoInfo.id
         const promotionId = order.promotionInfo.id
         const memberId = order.member.id

         const memberResponse = await Members.get(
				casinoId,
				promotionId,
				memberId,
				{
					ignoreFields: [
						'createdAt',
						'updatedAt',
						'createdBy',
						'updatedBy'
					]
				}
			)

         const userResponse = await Users.getUser(order.userInfo.uid)
         order.userInfo = Users.getUserInfo(userResponse)

         if (memberResponse) {

            order.member = memberResponse

            console.log('Retrying with :', JSON.stringify(order))

            await Orders.deleteOrder(order.id)

            delete order.failureError
            delete order.tableData

            order.status = 'created'
            order.statusHistory = ['created']

            console.log('New Order Object', order)
            const response = await Orders.add(order.id, order)

            window.location.reload();
            
            console.log('Response', response)

            // onUpdateOrder(response as Orders.Order)
         }

      } catch (error) {
         console.log('Error Recreating Order', error)
         Alert.alert('Error Recreating Order', String(error))
      }
      setShowLoader(false)
   }

   // function onChangeValue(path: string, value: any){
   //    const ___order = {...order}
   //    lodash.set(___order, path, value)
   //    setOrder(___order)
   // }

   async function onPressSaveLink(){
      setShowLoader(true)
      try {
         const response = await Orders.updateOrder(order.id, {
            overrideTrackingLink: overrideLink
         })
         onUpdateOrder({
            ...order,
            ...response
         })
      } catch (error) {
         console.log('Error Updating Link', error)
      }
      setShowLoader(false)
   }
}

async function updateActiveOrders(orderIds?: string[]) {

   return new Promise(async (resolve, reject) => {
      if ((orderIds ?? []).length > 0) {
         console.log('Syncing Orders', orderIds)
      }
      try {
         const url = 'https://us-central1-continuity-gifting-app.cloudfunctions.net/updateActiveOrders'
         const response = await fetch(url,{
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               'Accept': '*/*',
            },
            body: JSON.stringify({
               orderIds: orderIds ?? []
            })
         })
         if (response.ok) {
            const responseJSON = await response.json()
            console.log('Response',responseJSON)
            resolve(responseJSON)
         } else {
            throw new Error(response.statusText);
         }
      } catch (error) {
         console.log('Error Updating Active Orders',error)
         Alert.alert('Error Updating Active Orders', String(error))
         reject(error)
      }
   })
}