import React, { useEffect, useState } from 'react'
import { View, Text, Image, ActivityIndicator, FlatList } from 'react-native'
import MaterialTable, { Column } from 'material-table';
import Casinos, {
   LatLng, LocationCheckOptions
} from 'cs-connect/lib/Casinos'
import { Alert } from './utils/Alert'
import firestar from 'firestar'
import lodash, { reject } from 'lodash'
import ImageUploading, { ImageListType } from 'react-images-uploading';
import {
   Link
} from 'react-router-dom';
import ArrowRight from '@mui/icons-material/KeyboardArrowRight'
import Button from './components/Button'
import CheckBox from './components/CheckBox'
import TextField from './utils/TextField'
import Users, { RoleIdOptions } from 'cs-connect/lib/Users';
import {
   createGeoPoint
} from 'firestar/lib/Utils'

var QRCode = require('qrcode.react');

const INITIAL_PAGESIZE = 100
const PAGESIZE = 10

const Columns = (): Column<Casinos.Casino>[] => [
   {
      field: 'id',
      title: 'ID',
      editable: 'never',
      headerStyle: { fontWeight: 'bold' },
   },
   {
      field: 'name',
      title: 'Name',
      editable: 'always',
      headerStyle: { fontWeight: 'bold' },
   },
   {
      field: 'address.city',
      title: 'City',
      editable: 'always',
      headerStyle: { fontWeight: 'bold' },
   },
   {
      field: 'address.zipCode',
      title: 'ZipCode',
      editable: 'always',
      headerStyle: { fontWeight: 'bold' },
   },
   {
      field: 'createdAt',
      title: 'Added On',
      editable: 'never',
      headerStyle: { fontWeight: 'bold' },
      render: (c) => (
         <Text>{c.createdAt.toDate().toDateString()}</Text>
      )
   }
]

export default function CasinoList() {

   const [casinos, setCasinos] = useState<Casinos.Casino[]>([])
   const [showLoader, setShowLoader] = useState(false)

   useEffect(() => {
      fetchCasinos()
   }, [])

   async function fetchCasinos() {
      setShowLoader(true)
      try {
         const response = await Casinos.getCasinos({
            limit: 100,
            orderBy: {
               fieldPath: 'createdAt',
               directionStr: 'desc'
            }
         })
         setCasinos(response.data)
      } catch (error) {
         Alert.alert(
            'Error Fetching Casino List',
            String(error)
         )
      }
      setShowLoader(false)
   }

   return (
      <div style={{
         padding: 15
      }}>
         <MaterialTable
            title='Casinos'
            isLoading={showLoader}
            data={casinos}
            onChangePage={onChangePage}
            options={{
               search: true,
               pageSizeOptions: [PAGESIZE],
               pageSize: PAGESIZE,
               addRowPosition: 'first',
               searchFieldVariant: 'outlined',
               searchFieldStyle: {
                  marginTop: '1em'
               }
            }}
            detailPanel={(props) => (
               <DetailPanel
                  onUpdateCasino={onUpdateCasino}
                  casino={props} />
            )}
            editable={{
               onRowAdd: onRowAdd,
               onRowUpdate: onRowUpdate
            }}
            columns={Columns()}
         />
      </div>
   )

   function onUpdateCasino(casinoId: string, casino: Casinos.Casino) {
      return new Promise<Casinos.Casino>(async (resolve, reject) => {

         const __updatingCasino = { ...casino }
         delete (__updatingCasino as any).tableData

         console.log('__updatingCasino',__updatingCasino)

         try {
            if (casino.coverImage) {
               if (casino.coverImage?.startsWith('data')) {
                  console.log('Uploading Cover Image')
                  const response = await Casinos.uploadCoverImage(casinoId, casino.coverImage)
                  __updatingCasino.coverImage = response.downloadUrl
               }
            } else {
               __updatingCasino.coverImage = null
            }

         } catch (error) {
            console.log('Error Uploading Cover Image : ', error)
         }

         try {

            if (casino.thumbnail) {
               if (casino.thumbnail?.startsWith('data')) {
                  console.log('Uploading Thumbnail')
                  const response = await Casinos.uploadThumbnail(casinoId, casino.thumbnail)
                  __updatingCasino.thumbnail = response.downloadUrl
               }
            } else {
               __updatingCasino.thumbnail = null
            }

         } catch (error) {
            console.log('Error Uploading Thumbnail : ', error)
         }

         if (__updatingCasino.address?.coordinates != null) {

            const coordinates = __updatingCasino.address?.coordinates
            const latitudeString = (coordinates.split(',')[0]).trim()
            const longitudeString = (coordinates.split(',')[1]).trim()

            const latitude = (Number(latitudeString)) ?? 0
            const longitude = (Number(longitudeString)) ?? 0
            __updatingCasino.coordinates = createGeoPoint(latitude, longitude)
         }

         try {

            const _ = await Casinos.updateCasino(casinoId, __updatingCasino)

            const __casinos = [...casinos]
            const findIndex = __casinos.findIndex(c => c.id == casinoId)
            if (findIndex >= 0) {
               __casinos[findIndex] = __updatingCasino
               resolve(__updatingCasino)
            } else {
               __casinos.push(__updatingCasino)
            }
            setCasinos(__casinos)
            resolve(__updatingCasino)
            console.log('Updated Casino : ', casinoId)
         } catch (error) {
            console.log('Error Updating Casino : ', error)
            reject(error)
         }

      })
   }

   function onChangePage(page: number, pageSize: number) {
      if (casinos.length / pageSize / page <= (INITIAL_PAGESIZE / PAGESIZE)) {

      }
   }

   function onRowAdd(data: Casinos.Casino) {
      return new Promise<void>(async (resolve, reject) => {
         try {
            const response = await Casinos.addCasino(data)
            if((data.name ?? '').trim().length == 0){
               throw new Error("Enter casino name");
            }
            const __casinos = [response, ...casinos]
            setCasinos(__casinos)
            resolve()
         } catch (error) {
            Alert.alert('Error Adding Casinos', String(error))
            reject(error)
         }
      })
   }

   function onRowUpdate(newData: Casinos.Casino) {
      return new Promise<any>(async (resolve, reject) => {
         try {

            const changedData: Casinos.Casino = {
               ...casinos.find(c => c.id == newData.id)
            } as Casinos.Casino
            Columns()
               .filter(c => c.editable == 'always' || c.editable == 'onUpdate')
               .forEach(c => {
                  lodash.set(changedData, c.field ?? '', lodash.get(newData, c.field ?? '') ?? null)
               })

            delete (changedData as any).tableData

            await Casinos.updateCasino(changedData.id, changedData)

            resolve(newData)

         } catch (error) {
            Alert.alert('Error Adding Casinos', String(error))
            reject(error)
         }
      })
   }
}

function DetailPanel(props: {
   casino: Casinos.Casino,
   onUpdateCasino: (casinoId: string, casino: Casinos.Casino) => Promise<Casinos.Casino>
}) {

   const [casino, setCasino] = useState(lodash.cloneDeep(props.casino))
   const [showLoader, setShowLoader] = useState(false)
   const [searchingUser, setSearchingUser] = useState(false);
   const [users, setUsers] = useState<Casinos.CasinoUser[]>([])

   useEffect(() => {
      fetchUsers()
   }, [])
   

   // useEffect(() => {
   //    console.log('Casino', casino)
   // }, [casino])

   return (
      <View style={{
         padding: 15
      }}>
         {showLoader == true && (
            <View style={{
               position: 'absolute',
               left: 0,
               right: 0,
               bottom: 0,
               top: 0,
               backgroundColor: '#11111190',
               zIndex: 1,
               justifyContent: 'center',
               alignItems: 'center'
            }}>
               <ActivityIndicator />
            </View>
         )}
         <View style={{
            flexDirection: 'row',
            justifyContent: 'space-between'
         }}>
            <View>
               <SectionPanel
                  title='QR Image'
                  render={() => (
                     <View>
                        <QRCode value={'https://app.infigifts.com/casinos/' + casino?.id} />
                     </View>
                  )} />
               <SectionPanel
                  title='Shopify Info'
                  render={() => (
                     <View>
                        <TextField
                           style={{
                              minWidth: 400
                           }}
                           label='Collection Id'
                           placeholder='Enter Shopify Collection Id'
                           value={casino.collectionId ?? ''}
                           onChangeText={(value) => {
                              onChangeValue('collectionId', value)
                           }} />
                     </View>
                  )} />
               <SectionPanel
                  title='Radius & Bounds'
                  render={() => (
                     <View>

                        <select 
                           style={{
                              marginBottom: 10
                           }}
                           value={casino.locationCheck ?? 'radius'}
                           onChange={(event) => {
                              onChangeValue('locationCheck', event.target.value)
                           }}
                           name="locationCheck" id="locationCheck">
                           {Object.keys(LocationCheckOptions).map(
                              (key) => <option value={key}>{LocationCheckOptions[key]}</option>
                           )}
                        </select>

                        <TextField
                           style={{
                              marginVertical: 10
                           }}
                           label='Radius'
                           value={casino.radius}
                           placeholder='Enter a number greater than 0'
                           onBlur={() => {
                              if(String(casino.radius).length > 0){
                                 onChangeValue('radius', parseInt(String(casino.radius)))
                              }
                           }}
                           onChangeText={(value) => {
                              const __value = value.trim()
                              onChangeValue('radius', __value)
                           }} />

                        <TextField
                           style={{
                              marginVertical: 10
                           }}
                           label='Coordinates'
                           value={casino.address?.coordinates}
                           placeholder='Enter a number greater than 0'
                           onChangeText={(value) => {
                              onChangeValue('address.coordinates', value)
                           }} />

                        <TextField
                           label='Bounds Coordinates'
                           style={{
                              marginVertical: 10
                           }}
                           value={(casino.bounds ?? []).map((c) => `${c.latitude},${c.longitude}`).join(' ')}
                           placeholder='Paste Lat,Lng Ex: 12.95372,77.51734 12.89746,77.606758'
                           onChangeText={(value) => {
                              if((value ?? '').trim().length > 0){
                                 const split = (value ?? '').split(' ')
                                 const coordinates = split.map((value) => {
                                    const coordsSplit = value.trim().split(',')
                                    return {
                                       latitude: parseFloat(coordsSplit[0].trim()),
                                       longitude: parseFloat(coordsSplit[1].trim())
                                    }
                                 }) as LatLng[]
                                 onChangeValue('bounds', coordinates)
                              }else{
                                 onChangeValue('bounds', [])
                              }
                           }} />
						<CheckBox onPress={() => {
							  onChangeValue('askCardNumber', !(casino.askCardNumber ?? false))
						}} containerStyle={{
									   marginLeft: 0,
									   backgroundColor: 'white'
								   }} checked={(casino.askCardNumber ?? false)} title='Ask Card Number' />
						<TextField
							style={{
								marginVertical: 10
							}}
							label='Points factor'
							defaultValue={casino.pointFactor ?? 1}
							placeholder='Enter a number greater than 0'
							onBlur={(e) => [
								onChangeValue('pointFactor', Number(e.target.value))
							]}
							onChangeText={(value) => {
								
							}} />
					</View>
				)} />
               <SectionPanel title='' render={() => (
                  <View style={{
                     width: 600
                  }}>
                     <MaterialTable
                        title={'Users'}
                        options={{
                           search: false,
                           pageSize: 5,
                           pageSizeOptions: [5],
                           paging: false
                        }}
                        isLoading={searchingUser}
                        editable={{
                           onRowAdd: onRowAddUser,
                           onRowDelete: onRowDeleteUser
                        }}
                        data={users}
                        columns={[
                           {
                              title: 'User',
                              render: (user) => (
                                 <View>
                                    <Text>{user.displayName}</Text>
                                    <Text>{user.email}</Text>
                                 </View>
                              ),
                              editable: 'onAdd',
                              field: 'email', 
                              editPlaceholder: 'Enter Email'
                           },
                           {
                              title: 'Level',
                              editable: 'always',
                              field: 'role.level',
                              lookup: RoleIdOptions,
                              editPlaceholder: 'Select Role'
                           }
                        ]}
                     />
                  </View>
               )} />
            </View>
            <View>
               <SectionPanel
                  title='Cover Image'
                  render={() => (
                     <View>
                        <ImageUploading
                           value={[{
                              dataURL: casino.coverImage ?? ''
                           }]}
                           onChange={onSelectCoverImage}>
                           {({
                              onImageUpload,
                              onImageRemove,
                           }) => {
                              return (
                                 <View style={{
                                    alignItems: 'center'
                                 }}>
                                    <Image
                                       style={{
                                          width: 300,
                                          height: 100,
                                          borderWidth: 1,
                                          borderColor: '#dddddd',
                                          borderRadius: 2
                                       }}
                                       resizeMode='contain'
                                       source={{
                                          uri: casino.coverImage ?? ''
                                       }} />
                                    <Button
                                       type='text'
                                       titleStyle={{
                                          fontSize: 12
                                       }}
                                       containerStyle={{
                                          marginTop: 10
                                       }}
                                       title={casino.coverImage != null ? 'Remove Image' : 'Upload Image'}
                                       onPress={() => {
                                          if (casino.coverImage) {
                                             onImageRemove(0)
                                          } else {
                                             onImageUpload()
                                          }
                                       }} />
                                 </View>
                              )
                           }}
                        </ImageUploading>
                     </View>
                  )} />
               <SectionPanel
                  title='Thumbnail'
                  render={() => (
                     <View>
                        <ImageUploading
                           value={[{
                              dataURL: casino.thumbnail ?? ''
                           }]}
                           onChange={onSelectThumbnail}>
                           {({
                              onImageUpload,
                              onImageRemove,
                           }) => {
                              return (
                                 <View style={{
                                    alignItems: 'center'
                                 }}>
                                    <Image
                                       resizeMode='contain'
                                       style={{
                                          width: 200,
                                          height: 200,
                                          borderWidth: 1,
                                          borderColor: '#dddddd',
                                          borderRadius: 2
                                       }}
                                       source={{
                                          uri: casino.thumbnail ?? ''
                                       }} />
                                    <Button
                                       type='text'
                                       titleStyle={{
                                          fontSize: 12
                                       }}
                                       containerStyle={{
                                          marginTop: 10
                                       }}
                                       title={casino.thumbnail != null ? 'Remove Image' : 'Upload Thumbnail'}
                                       onPress={() => {
                                          if (casino.thumbnail) {
                                             onImageRemove(0)
                                          } else {
                                             onImageUpload()
                                          }
                                       }} />
                                 </View>
                              )
                           }}
                        </ImageUploading>
                     </View>
					   )} />
			   </View>
		   </View>         
         <View>
            <Button
               containerStyle={{
                  width: 200
               }}
               disabled={lodash.isEqual(casino, props.casino)}
               onPress={onPressSave}
               title='Save' />
         </View>
      </View>
   )

   function onRowDeleteUser(user: Casinos.CasinoUser) {
      return new Promise<void>(async (resolve) => {
         const __users = [...users]
         const findIndex = __users.findIndex(u => u.id == user.id)
         await Casinos.deleteUser(casino.id, user.id)
         if (findIndex >= 0) {
            __users.splice(findIndex, 1)
         }
         setUsers(__users)
         resolve()
      })
   }

   function onRowAddUser(user: Casinos.CasinoUser) {
      return new Promise<null | Casinos.CasinoUser>(async (resolve, reject) => {

         setSearchingUser(true)
         try {

            const __users = [...users]
            const findIndex = __users.findIndex(u => u.uid === user.uid)
            const email = user.email ?? ''

            if (findIndex < 0 && email.trim().length > 0) {
               const response = await Users.getUserInfoWithEmail(email)
               if(response == null){
                  throw new Error('User Not Found')
               }
               const userId = response.uid
               const newCasinoUser = {
                  ...Users.getUserInfo(response),
                  role: {
                     id: casino.id,
                     level: user.role.level,
                     name: casino.name,
                     // paths: ['*'],
                     type: 'casino'
                  }
               } as Casinos.CasinoUser

               const addResponse = await Casinos.addUser(casino.id, userId, newCasinoUser)
               __users.push(addResponse as Casinos.CasinoUser)
               setUsers(__users)
               resolve(newCasinoUser)
            }else{
               throw new Error('Enter Valid Details')
            }
         } catch (error) {
            console.log('Error Adding User : ',error)
            Alert.alert(
               'Error Adding User',
               String(error)
            )
            reject(error)
         }
         setSearchingUser(false)
      })
   }

   async function onPressSave() {
      setShowLoader(true)
      try {
         const response = await props.onUpdateCasino(casino.id, casino)
         setCasino(response)
      } catch (error) {
         
      }
      setShowLoader(false)
   }

   function onSelectCoverImage(value?: ImageListType) {
      if ((value ?? []).length > 0) {
         setCasino({
            ...casino,
            coverImage: value![0].dataURL ?? undefined
         })
      } else {
         setCasino({
            ...casino,
            coverImage: undefined
         })
      }
   }

   function onSelectThumbnail(value: ImageListType) {
      if ((value ?? []).length > 0) {
         setCasino({
            ...casino,
            thumbnail: value![0].dataURL ?? undefined
         })
      } else {
         setCasino({
            ...casino,
            thumbnail: undefined
         })
      }
   }

   function onChangeValue(path:string,value:any){
      const __casino = {...casino}      
      lodash.set(__casino, path, value)
      setCasino(__casino)
   }

   async function fetchUsers() {
      try {
         const response = await Casinos.getUsers(casino.id, {
            limit: 100
         })
         setUsers(response.data)
         console.log("Response Users", response.data)
      } catch (error) {
         console.log('Error Getting Users', error)
      }
   }
}

function SectionPanel(props: {
   title: string,
   render: () => any
}) {
   const { render, title } = props
   return (
      <View style={{
         padding: 15
      }}>
         <Text style={{
            marginBottom: 10,
            fontSize: 16
         }}>{title}</Text>
         {<View>
            {render()}
         </View>}
      </View>
   )
}
