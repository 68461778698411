import React, { useState } from 'react'
import { View, Text, Alert } from 'react-native'
import Button from './components/Button'

export default function Login(props: {
	loginWithGoogle: () => void
}) {

	const { loginWithGoogle } = props
	const [showLoader, setShowLoader] = useState(false)

	if (showLoader === true) {
		return (
			<Text>Loading...</Text>
		)
	}

	return (
		<View style={{
			padding: 10,
			alignSelf: 'center',
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center',
			minHeight: '100vh'
		}}>
			<Button
				onPress={onPressLogin}
				title='Login with Google' />
		</View>
	)

	async function onPressLogin() {
		setShowLoader(true)
		try {
			await loginWithGoogle()
		} catch (error) {
			console.log('Error SignIn with Pop Up', error)
			Alert.alert('Error Signing In', String(error))
		}
		setShowLoader(false)
	}
}
