/* eslint-disable react-hooks/exhaustive-deps */
import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState
} from "react";
import { auth } from '../firebase'
import firebase from 'firebase/compat/app';
import ProgressView from "../components/Progress";
import { useDebounce } from "../utils";
import { Users } from "cs-connect";
import Login from "../Login";

type UserState = 'loggedIn' | 'loggedOut' | 'anonymous' | 'loading'

type ContextType = {
	user: firebase.User;
	userState: UserState
	loginUser: (email: string, password: string) => Promise<firebase.auth.UserCredential>,
	logOut: () => void;
	resetPassword: (email: string) => Promise<void>
	claims: Users.Claims
};

const AuthContext = createContext<ContextType>({
	userState: 'loggedOut'
} as ContextType);

export const useAuthContext = () => useContext(AuthContext);

export default function AuthContextProvider(props: {
	children: React.ReactNode;
}) {
	const [user, setUser] = useState<firebase.User | null>();
	const debouncedUser = useDebounce(user, 1000);
	const [fetchingAuthState, setFetchingAuthState] = useState(true)
	const onAuthStateChangedCallback = useCallback(onAuthStateChanged, [])
	const [claims, setClaims] = useState<Users.Claims>()
	const roles = useMemo(() => claims?.roles ?? {}, [claims])
	const currentRole = useMemo(() => roles['account'], [roles])

	const { children } = props;

	const userState: UserState = useMemo(() => {
		if (fetchingAuthState === false) {
			if ((user?.uid != null)) {
				return 'loggedIn'
			} else {
				return 'loggedOut'
			}
		} else {
			return 'loading'
		}
	}, [user, fetchingAuthState])

	useEffect(() => {
		if (typeof debouncedUser !== 'undefined') {
			console.log('Auth State Changed:', debouncedUser?.uid, 'Anonymous:', debouncedUser?.isAnonymous, 'Display Name:', debouncedUser?.displayName)
			setFetchingAuthState(false)
		}
	}, [debouncedUser]);

	useEffect(() => {
		if (fetchingAuthState === false) {
			if (user) {
				user?.getIdTokenResult(true).then((response) => {
					setClaims(response.claims ?? {})
				})
			}
		}
	}, [fetchingAuthState, userState, user])

	useEffect(() => {
		const unsubscribe = auth().onAuthStateChanged(onAuthStateChangedCallback);
		return () => {
			unsubscribe();
		};
	}, []);

	useEffect(() => {
		if(userState === 'loggedIn' && claims != null && currentRole == null){
			setTimeout(() => {
				alert('You do not have access to this portal.')
				logOut()
			}, 1000);
		}
	}, [userState, claims, currentRole])

	if ((userState === 'loading')) {
		return <ProgressView title="Authenticating user..." />
	}

	if (userState === 'loggedOut' || user == null) {
		return (
			<div style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				minHeight: '100vh'
			}}>
				<Login loginWithGoogle={loginWithGoogle} />
			</div>
		)
	}

	if (claims == null) {
		return <ProgressView title="Fetching user roles..." />
	}

	if(currentRole == null){
		return <ProgressView title="No roles were found" />
	}

	return (
		<>
			<AuthContext.Provider
				value={{
					user,
					loginUser,
					logOut,
					resetPassword,
					userState,
					claims
				}}>
				{children}
			</AuthContext.Provider>
		</>
	);

	async function onAuthStateChanged(_user: firebase.User | null) {
		setUser(_user)
	}

	// Login using email and password
	async function loginUser(email: string, password: string) {
		return new Promise<firebase.auth.UserCredential>(async (resolve, reject) => {
			try {
				const userCredential = auth.EmailAuthProvider.credential(email, password)
				const response = await auth().signInWithCredential(userCredential)
				resolve(response)
			} catch (error) {
				reject(error)
			}
		})
	}
	async function logOut() {
		try {
			await auth().signOut();
			console.log("Sucessfull logout");
		} catch (error) {
			console.log("Error at the logging Out", error);
		}
	}

	async function resetPassword(email: string) {
		try {
			await auth().sendPasswordResetEmail(email);
		} catch (error) {
			console.log("reset password", error);
		}
	}

	async function loginWithGoogle() {
		return new Promise<firebase.auth.UserCredential>(async (resolve, reject) => {
			try {
				const provider = new firebase.auth.GoogleAuthProvider()
				provider.setCustomParameters({ prompt: 'select_account' });
				const popUpResponse = await firebase.auth().signInWithPopup(provider)
				if (popUpResponse.credential != null) {
					const response = await firebase.auth().signInWithCredential(popUpResponse.credential)
					resolve(response)
				} else {
					throw new Error("Error generatig credential");
				}
			} catch (error) {
				reject(error)
			}
		})
	}
}
