"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatusOptions = exports.ChannelOptions = void 0;
const firestar_1 = require("firestar");
const _1 = require(".");
exports.ChannelOptions = {
    'mobile': 'Mobile',
    'web': 'Web'
};
exports.StatusOptions = {
    'draft': 'Draft',
    'published': 'Published',
    'completed': 'Completed',
};
var Promotions;
(function (Promotions) {
    function getPromotions(casinoId, descriptor) {
        return firestar_1.Actions.readCollection(`casinos/${casinoId}/promotions`, descriptor);
    }
    Promotions.getPromotions = getPromotions;
    function getPromotionInfo(promotion) {
        return {
            id: promotion.id,
            name: promotion.name
        };
    }
    Promotions.getPromotionInfo = getPromotionInfo;
    function addPromotion(casinoId, document) {
        return firestar_1.Actions.addToCollection(`casinos/${casinoId}/promotions`, document);
    }
    Promotions.addPromotion = addPromotion;
    function updatePromotion(casinoId, promotionId, document) {
        return firestar_1.Actions.updateDocument(`casinos/${casinoId}/promotions/${promotionId}`, document);
    }
    Promotions.updatePromotion = updatePromotion;
    function uploadThumbnail(casinoId, promotionId, data) {
        const fileType = data.substring("data:image/".length, data.indexOf(";base64"));
        console.log('fileType', fileType);
        return firestar_1.Storage.uploadFileDataToPath(`casinos/${casinoId}/promotions/${promotionId}/thumbnail.${fileType !== null && fileType !== void 0 ? fileType : 'png'}`, data, 'data_url', {
            contentType: 'image/ong'
        });
    }
    Promotions.uploadThumbnail = uploadThumbnail;
    function getPromotion(casinoId, promotionId, readOptions) {
        return firestar_1.Actions.readDocument(`casinos/${casinoId}/promotions/${promotionId}`, readOptions);
    }
    Promotions.getPromotion = getPromotion;
    function getPromotionWithId(promotionId) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await firestar_1.Actions.readCollectionGroup('promotions', {
                    where: [
                        {
                            fieldPath: 'id',
                            opStr: '==',
                            value: promotionId
                        }
                    ],
                });
                if (response.data.length == 1) {
                    resolve(response.data[0]);
                }
                else {
                    resolve(undefined);
                }
            }
            catch (error) {
                reject(error);
            }
        });
    }
    Promotions.getPromotionWithId = getPromotionWithId;
    function deletePromotion(casinoId, promotionId) {
        return firestar_1.Actions.deleteDocument(`casinos/${casinoId}/promotions/${promotionId}`);
    }
    Promotions.deletePromotion = deletePromotion;
    function isPromotionActive(promotion) {
        const startDate = promotion.startDate;
        const endDate = promotion.endDate;
        const startTime = startDate.toDate().getTime(); //1
        const endTime = endDate.toDate().getTime(); // 3
        const nowTime = new Date().getTime(); // 2
        return nowTime >= startTime && nowTime <= endTime;
    }
    Promotions.isPromotionActive = isPromotionActive;
    function getPublishedPromotionsForCasinos(casinoId, descriptor, readOptions) {
        return new Promise(async (resolve, reject) => {
            var _a;
            try {
                const response = await firestar_1.Actions.readCollection(`casinos/${casinoId}/promotions`, Object.assign(Object.assign({}, descriptor), { where: [
                        ...(_a = descriptor === null || descriptor === void 0 ? void 0 : descriptor.where) !== null && _a !== void 0 ? _a : [],
                        {
                            fieldPath: 'status',
                            opStr: '==',
                            value: 'published'
                        },
                        {
                            fieldPath: 'endDate',
                            opStr: '>=',
                            value: new Date()
                        }
                    ], orderBy: {
                        fieldPath: 'endDate',
                        directionStr: 'asc'
                    } }), readOptions);
                const promotions = response.data;
                // const filtered = promotions.filter((p) => {
                //    const startDate = p.startDate as firebase.firestore.Timestamp
                //    const startTime = startDate.toDate()
                //    const nowTime = new Date()
                //    return nowTime >= startTime
                // })
                resolve({
                    data: promotions,
                    lastDoc: response.lastDoc
                });
            }
            catch (error) {
                reject(error);
            }
        });
    }
    Promotions.getPublishedPromotionsForCasinos = getPublishedPromotionsForCasinos;
    function getActivePromotionsForCasinos(casinoId, descriptor, readOptions) {
        return new Promise(async (resolve, reject) => {
            var _a;
            try {
                const response = await firestar_1.Actions.readCollection(`casinos/${casinoId}/promotions`, Object.assign(Object.assign({}, descriptor), { where: [
                        ...(_a = descriptor === null || descriptor === void 0 ? void 0 : descriptor.where) !== null && _a !== void 0 ? _a : [],
                        {
                            fieldPath: 'status',
                            opStr: '==',
                            value: 'published'
                        },
                        {
                            fieldPath: 'endDate',
                            opStr: '>=',
                            value: new Date()
                        }
                    ] }), readOptions);
                const promotions = response.data;
                const filtered = promotions.filter((p) => {
                    const startDate = p.startDate;
                    const startTime = startDate.toDate();
                    const nowTime = new Date();
                    return nowTime >= startTime;
                });
                resolve({
                    data: filtered,
                    lastDoc: response.lastDoc
                });
            }
            catch (error) {
                reject(error);
            }
        });
    }
    Promotions.getActivePromotionsForCasinos = getActivePromotionsForCasinos;
    function getPromotionsForCardNumber(casinoId, cardNumber) {
        return new Promise(async (resolve, reject) => {
            try {
                console.log('Getting eligible promotions for card number', cardNumber);
                const promotionResponse = await getActivePromotionsForCasinos(casinoId, {
                    limit: 100
                });
                const activePromotions = promotionResponse.data;
                const lastDoc = promotionResponse.lastDoc;
                const promises = activePromotions.map((promotion) => {
                    return new Promise(async (resolve, reject) => {
                        try {
                            const response = await _1.Members.getAllMembersForPromotion(casinoId, promotion.id, {
                                where: [
                                    {
                                        fieldPath: 'cardNumber',
                                        opStr: '==',
                                        value: cardNumber
                                    }
                                ]
                            });
                            if (response.data.length > 0) {
                                resolve(response.data[0]);
                            }
                            else {
                                resolve(null);
                            }
                        }
                        catch (error) {
                            reject(error);
                        }
                    });
                });
                const memberResponse = await Promise.all(promises);
                console.log('memberResponse', memberResponse);
                const eligiblePromotions = memberResponse.map((member, index) => {
                    if (member != null) {
                        const promotion = activePromotions[index];
                        return promotion;
                    }
                    return null;
                }).filter((p) => p != null);
                resolve({
                    data: eligiblePromotions,
                    lastDoc: lastDoc
                });
            }
            catch (error) {
                reject(error);
            }
        });
    }
    Promotions.getPromotionsForCardNumber = getPromotionsForCardNumber;
})(Promotions || (Promotions = {}));
exports.default = Promotions;
