import React, { createContext, useContext, useEffect, useState } from 'react'
import ProgressView from '../components/Progress'
import firebase from 'firebase/compat/app'
import { firestore } from '../firebase'
import { useAuthContext } from './AuthContextProvider';

type Props = {
	children: React.ReactNode;
};

interface UserDoc extends firebase.UserInfo, firebase.firestore.DocumentData {
	updatedAt: firebase.firestore.Timestamp,
	features?: string[]
}

type UserContextType = {
	userDoc: UserDoc;
}

const UserContext = createContext<UserContextType>({} as UserContextType);

export const useUserContext = () => useContext(UserContext);

export default function UserContextProvider({ children }: Props) {

	const [userDoc, setUserDoc] = useState<UserDoc>();
	const { user } = useAuthContext()

	useEffect(() => {
		fetchUserDocByUid()
	}, [])

	if (userDoc == null) return <ProgressView title="Getting user profile..." />

	return (
		<UserContext.Provider value={{
			userDoc
		}}>
			{children}
		</UserContext.Provider>
	);

	async function fetchUserDocByUid() {
		try {
			const docSnapshot = await firestore().collection("users").doc(user.uid).get({ source: 'server' });
			const result = docSnapshot.data() as UserDoc

			console.log('User Doc', result)
			setUserDoc(result as UserDoc);
		} catch (error) {
			console.error("Firestore error", error);
		}
	}
}
