import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import SearchOutlined from '@material-ui/icons/SearchOutlined'
import ButtonBase from '@material-ui/core/ButtonBase'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@mui/material/InputAdornment';

export default function SearchBar(props: any) {

	const { placeholder, onChangeText, value, containerStyle, inputStyle, onClear } = props
	return (
		<div style={{
			display: 'flex',
			flex: 1,
			justifyContent: 'flex-end',
			...containerStyle
		}}>
			<TextField
				type='search'
				variant='outlined'
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<SearchOutlined />
						</InputAdornment>
					),
					endAdornment: (
						<ButtonBase style={{
							opacity: value.length == 0 ? 0.3: 1
						}} disabled={value.length == 0}>
							<CloseIcon onClick={onClear} />
						</ButtonBase>
					)
				}}
				style={{
					...inputStyle,
				}}
				value={value}
				placeholder={placeholder}
				onChange={({ target }) => {
					onChangeText(target.value)
				}} />			
		</div>
	)
}
