"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Admin;
(function (Admin) {
    function addRole(userId, role, fAdmin) {
        return new Promise(async (resolve, reject) => {
            // try {
            //    if (userId == null)
            //       throw new Error('User Id missing')
            //    if (role == null)
            //       throw new Error('Role Missing')
            //    if ((role.casinoId ?? '').length > 0 && role.level == 'global') {
            //       throw new Error('Global Role Level cannot be assigned to a casino')
            //    }
            //    const userRecord = await fAdmin.auth().getUser(userId)
            //    if (userRecord == null)
            //       throw new Error('User Not Found')
            //    let claims = (userRecord?.customClaims ?? {
            //       roles: []
            //    }) as Users.Claims
            //    if ((claims.roles ?? []).length == 0) {
            //       claims.roles = []
            //    }
            //    const findIndex = claims.roles.findIndex(
            //       r => (r.casinoId ?? '') == (role.casinoId ?? '') && r.level == role.level
            //    )
            //    if (findIndex < 0) {
            //       claims.roles.push(role)
            //    } else {
            //       claims.roles[findIndex] = role
            //    }
            //    await fAdmin.auth().setCustomUserClaims(userId, claims)
            //    resolve(claims)
            // } catch (error) {
            //    reject(error)
            // }
        });
    }
    Admin.addRole = addRole;
    function removeRole(userId, role, fAdmin) {
        return new Promise(async (resolve, reject) => {
            // try {
            //    const userRecord = await fAdmin.auth().getUser(userId)
            //    if (userRecord == null) throw new Error('User Not Found')
            //    let claims = (userRecord?.customClaims ?? {
            //       roles: []
            //    }) as Users.Claims
            //    const roles = claims.roles ?? []
            //    if (roles.length === 0) {
            //       claims.roles = []
            //    }
            //    const findIndex = roles.findIndex(
            //       r => (r.casinoId ?? '') === (role.casinoId ?? '') && r.level === role.level
            //    )
            //    if (findIndex >= 0) {
            //       roles.splice(findIndex, 1)
            //    }
            //    claims.roles = roles
            //    await fAdmin.auth().setCustomUserClaims(userId, claims)
            //    resolve(claims)
            // } catch (error) {
            //    reject(error)
            // }
        });
    }
    Admin.removeRole = removeRole;
})(Admin || (Admin = {}));
exports.default = Admin;
