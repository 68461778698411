import React from 'react'

export default function Badge(props: any) {

	const { value, style } = props
	return (
		<div style={{
			background: 'red',
			textAlign: 'center',
			color: 'white',
			borderRadius: '50%',
			padding: '5px',
			width: 15,
			height: 15,
			fontSize: 12,
			fontWeight: 'bold',
			...style
		}}>{value}</div>
	)
}
