import React, { useEffect, useState } from 'react'
import { View, Text, Dimensions } from 'react-native'

const dimensions = Dimensions.get('window')

export default function Dashboard() {

   const [showLoader, setShowLoader] = useState(false)

   useEffect(() => {
      initialLoad()
   }, [])

   async function initialLoad(){
      setShowLoader(true)
      try {
         
      } catch (error) {
         
      }
      setShowLoader(false)
   }

   return (
      <iframe 
         width={dimensions.width}
         height={dimensions.height}
         src="https://datastudio.google.com/embed/reporting/1240759e-8069-4b02-a640-07f7d07326d4/page/aAZDD" 
         style={{ border: 0 }} />
   )
}


// function Card(props:{
//    title: string,
//    description?: string,
//    value: number,
//    isLoading: boolean,
//    oldValue?: number
// }){
//    const { title, value, description, isLoading, oldValue } = props
//    const change = value - (oldValue ?? 0)
//    return (
//       <View style={{
//          width: 210,
//          height: 150,
//          justifyContent: 'center',
//          alignItems: 'center',
//          borderWidth: 1,
//          borderColor: '#dddddd',
//          borderRadius: 10,
//          margin: 10
//       }}>
//          <Text style={{
//             textAlign: 'center',
//             fontWeight: '500'
//          }}>{title}</Text>
//          <View style={{
//           flexDirection: 'column',
//           alignItems: 'center',
//           justifyContent: 'center',
//           marginVertical: 20
//          }}>
//             <Text style={{
//                 fontSize: 35,
//                 textAlign:'center',
//             }}>{isLoading ? '--' :value ?? 0}</Text>
//             <Text style={{
//               fontSize: 15,
//               textAlign:'center',
//               color: change === 0 ? 'gray' : (change < 0 ? 'red' : '#19c246'),
//           }}>{change === 0 ? '±' : (change < 0 ? '' : '+')}{isLoading ? '' :change}</Text>
//          </View>
//          <Text style={{
//             fontSize: 13,
//             textAlign: 'center',
//             color: 'gray'
//          }}>{isLoading ? '--' : description}</Text>
//       </View>
//    )
// }
